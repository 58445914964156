import React from "react";
import MapStars from "../../Insertions/MapStars";
import DefaultThumbnail from "../../../assets/images/DefaultThumbnail.png";
import "./style.scss";

// @ts-ignore
const images = require.context("../../../assets/images", false, /\.(webp)$/);

const ImageStarContainer = ({ level }) => {
	const rating = parseFloat(level?.rating) || 0;
	const game: number | undefined = Number.isInteger(level?.game)
		? level?.game
		: undefined;

	const getImage = (game) => {
		const imageName = `GameLogo${game}.webp`;
		return imageName ? images(`./${imageName}`) : null;
	};

	const gameLogo = getImage(game);

	return (
		<div className="image-star-container">
			<div className="star-rating">
				{level?.rating > 0 &&
				level?.status !== "Private" &&
				level?.status !== "Deleted" ? (
					<MapStars
						level={level}
						rate={null}
						top=""
						color={
							rating >= 4.9 && level?.rates?.length > 5
								? "#2cd40f"
								: ""
						}
						width={window.innerWidth < 1000 ? "6mm" : "7.5mm"}
						height={window.innerWidth < 1000 ? "10mm" : "11mm"}
					/>
				) : (
					<div
						className={`default-rate 
						${level?.status === "Private" || level?.status === "Deleted" ? "vanish" : ""}`}>
						This level has no rates yet
					</div>
				)}
			</div>
			{/* Set to default thumbnail if no thumbnail is present */}
			<div
				className={`${
					level?.status === "Featured"
						? "featured-thumbnail"
						: "level-thumbnail"
				}`}>
				<img
					className="level-thumbnail__thumb"
					alt="Level Thumbnail"
					src={level?.thumbnail || DefaultThumbnail}
					onError={(ThumbError) => {
						// @ts-ignore
						ThumbError.target.onerror = null;
						// @ts-ignore
						ThumbError.target.src = DefaultThumbnail;
					}}
				/>
			</div>
			{/* Featured overlay display */}
			<span
				className={`${
					level?.status !== "Featured"
						? "vanish"
						: "featured-thumbnail__overlay"
				}`}
			/>
			{/* Game icon inside of banner */}
			{game !== undefined && (
				<div
					className="game-icon"
					style={{ backgroundImage: `url(${gameLogo})` }}
				/>
			)}
		</div>
	);
};

export default ImageStarContainer;
