// imports
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import decode from "jwt-decode";

// Import UserContext that contains the latest user information from the database
import { UserContext } from "../../App";

// page styles
import "./style.scss";

// react-bootstrap components used
import {
	Navbar,
	Nav,
	Container,
	OverlayTrigger,
	Tooltip,
	DropdownButton,
	Dropdown,
} from "react-bootstrap";
import { deleteUserSession } from "../../api";
import { toast } from "react-toastify";

/**
 * The header (navbar) that appears at the top of every page.
 *
 * @param {*} props Information to display in the header.
 * @returns The header for the site.
 */
const Header = (props) => {
	// set the state for the current logged in user, if there is one.
	const [localUserToken, setLocalUserToken] = useState(
		localStorage.getItem(props.sessionName)
	);

	// push history function for ease of use
	const pushHistory = (e, url) => {
		e.preventDefault();
		e.stopPropagation();
		window.scrollTo(0, 0);
		history.push(url);
	};

	// hook initializations
	const location = useLocation();
	const history = useHistory();

	// Obtain the current user data (from React UserContext), passed from the App component
	const currentUserData = useContext<any>(UserContext);

	// constants
	const currentLocation = props.currentLocation;
	const navChanged = props.navChanged;
	const setNavChanged = props.setNavChanged;
	const setCurrentLocation = props.setCurrentLocation;

	//! routing related checks, leave in at all costs, pings server when triggered, needed for page load speed
	useEffect(() => {
		return history.listen((location) => {
			if (currentLocation === null)
				return setCurrentLocation(location.pathname);
			if (location.pathname !== currentLocation && navChanged === false) {
				setNavChanged(true);
				setCurrentLocation(location.pathname);
			}
		});
	}, [
		history,
		navChanged,
		currentLocation,
		setNavChanged,
		setCurrentLocation,
	]);

	const hasNewNotifications = props?.hasNewNotifications;
	const setHasNewNotifications = props?.setHasNewNotifications;
	const newNotifications = props?.newNotifications;
	const setInfractionModalShow = props?.setInfractionModalShow;
	const handleMarkNotificationAsRead = props?.handleMarkNotificationAsRead;
	const setXpModalShow = props?.setXpModalShow;

	// toasting hook for notifications
	useEffect(() => {
		if (hasNewNotifications) {
			// prevent any multiple triggers
			setHasNewNotifications(false);
			let notificationArray = newNotifications;
			// set an interval to toast everything
			const toastInterval = setInterval(() => {
				if (notificationArray?.length > 0) {
					// dummy handler value
					const [firstNotification, ...remainingNotifications] =
						notificationArray;
					// toast notifications one by one.
					firstNotification?.type?.includes("infraction")
						? // warn if infraction
						  toast.warn(
								firstNotification?.content?.replace(
									/<\/?[ubia]>/g,
									""
								),
								{
									onClick: () => {
										// show infraction modal
										setInfractionModalShow(true);
										handleMarkNotificationAsRead(
											firstNotification?._id
										);
									},
									autoClose: 6000,
								}
						  )
						: // info toast if regular
						  toast.info(
								firstNotification?.content?.replace(
									/<\/?[ubia]>/g,
									""
								),
								{
									onClick: () => {
										// check if infraction related
										firstNotification?.type?.includes(
											"forgiveness"
										)
											? setInfractionModalShow(true)
											: firstNotification?.type?.includes(
													"rankup"
											  )
											? setXpModalShow(true)
											: history.push(
													firstNotification?.unSubscribe
														? firstNotification?.onClick +
																"?referer=" +
																firstNotification?.unSubscribe
														: firstNotification?.onClick
											  );
										handleMarkNotificationAsRead(
											firstNotification?._id
										);
									},
									autoClose: 8000,
								}
						  );
					notificationArray = remainingNotifications;
				} else {
					// finalise toast a message to see all if there are multiple notifications
					if (
						newNotifications?.length > 1 &&
						window.location.pathname !== "/notifications"
					)
						toast.success("Click here to view all notifications.", {
							onClick: () => {
								history.push("/notifications");
							},
							autoClose: 10000,
						});
					return clearInterval(toastInterval);
				}
			}, 1500);
		}
	}, [
		hasNewNotifications,
		newNotifications,
		history,
		setHasNewNotifications,
		handleMarkNotificationAsRead,
		setInfractionModalShow,
		setXpModalShow,
	]);

	// log out the currently logged in user (function, must be triggered)
	const handleLogout = () => {
		// remove the user token from the database
		deleteUserSession().then(() => props.logout());
	};

	// ensure correct user login information is set, and log the user out if their token has expired.
	useEffect(() => {
		// log the user out if their session token has expired
		if (localUserToken) {
			const decodedToken: any = decode(localUserToken);

			if (decodedToken.exp * 1000 < new Date().getTime())
				return handleLogout();
		}

		if (
			(currentUserData?.mainRoles.includes("Banned") ||
				currentUserData?.mainRoles.includes("Deactivated account")) &&
			!currentUserData?.mainRoles.includes("Admin")
		)
			return handleLogout();

		// set logged in user for a more responsive navbar
		setLocalUserToken(localStorage.getItem(props.sessionName));

		// eslint-disable-next-line
	}, [location, localUserToken]);

	return (
		<div
			style={{
				marginBottom: "120px",
			}}>
			<Navbar
				collapseOnSelect
				fixed="top"
				expand="lg"
				bg="dark"
				variant="dark"
				style={{
					paddingTop: "15px",
				}}
				id="websiteNav">
				<Container>
					{/* site logo and name */}
					<Navbar.Brand href="/" onClick={(e) => pushHistory(e, "/")}>
						{/* large screens */}
						<h4 className="d-none d-lg-block">
							<img
								src={props.images.logo}
								alt="LSS Logo"
								width="44"
								height="44"
								style={{
									borderRadius: "50%",
									borderWidth: "3px",
									borderStyle: "solid",
									borderColor: "white",
								}}
							/>
							<span
								className="align-middle"
								style={{ paddingLeft: "7px", color: "white" }}>
								Level Share Square
							</span>
						</h4>

						{/* mobile screens */}
						<h6 className="d-lg-none">
							<img
								src={props.images.logo}
								alt="LSS Logo"
								width="40"
								height="40"
								style={{
									borderRadius: "50%",
									borderWidth: "3px",
									borderStyle: "solid",
									borderColor: "white",
								}}
							/>
							<span
								className="align-middle"
								style={{ paddingLeft: "7px", color: "white" }}>
								Level Share Square
							</span>
						</h6>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse
						id="basic-navbar-nav"
						className="justify-content-end">
						<div>
							{/* buttons and login information if applicable */}
							<ul className="navbar-nav me-auto mb-2 mb-lg-0">
								{/*<Nav.Item>
									<div>
										<span className="material-icons gray">
											celebration
										</span>
										<span className="align-top gray">
											&nbsp;Events
										</span>
									</div>
							</Nav.Item>*/}
								<Nav.Item>
									<OverlayTrigger
										overlay={
											<Tooltip
												id="games"
												className="d-none d-lg-block">
												Play games
											</Tooltip>
										}
										placement="bottom">
										<Nav.Link
											href="/games"
											onClick={(e) =>
												pushHistory(e, "/games")
											}>
											<span className="material-icons">
												sports_esports
											</span>
											<span className="align-top">
												&nbsp;Games
											</span>
										</Nav.Link>
									</OverlayTrigger>
								</Nav.Item>
								<Nav.Item>
									<OverlayTrigger
										overlay={
											<Tooltip
												id="levels"
												className="d-none d-lg-block">
												Browse levels
											</Tooltip>
										}
										placement="bottom">
										<Nav.Link
											href="/levels"
											onClick={(e) => {
												const defaultGame = parseInt(
													currentUserData
														?.general_settings?.[0]
												);
												const pathname =
													window.location.pathname;

												if (
													defaultGame >= -1 &&
													defaultGame <
														props?.gameProperties
															?.gameAcronyms
															?.length &&
													(!pathname.includes(
														"/levels"
													) ||
														pathname.includes(
															"/levels/"
														))
												)
													return pushHistory(
														e,
														`/${defaultGame}/levels`
													);
												pushHistory(e, "/levels");
											}}>
											<span className="material-icons">
												view_list
											</span>
											<span className="align-top">
												&nbsp;Levels
											</span>
										</Nav.Link>
									</OverlayTrigger>
								</Nav.Item>
								<Nav.Item>
									<OverlayTrigger
										overlay={
											<Tooltip
												id="members"
												className="d-none d-lg-block">
												Browse members
											</Tooltip>
										}
										placement="bottom">
										<Nav.Link
											href="/members"
											onClick={(e) =>
												pushHistory(e, "/members")
											}>
											<span className="material-icons">
												supervisor_account
											</span>
											<span className="align-top">
												&nbsp;Members
											</span>
										</Nav.Link>
									</OverlayTrigger>
								</Nav.Item>
								{currentUserData?._id && (
									<>
										<Nav.Item className="bell-card">
											<OverlayTrigger
												overlay={
													<Tooltip
														id="notifications"
														className="d-none d-lg-block">
														Notifications
													</Tooltip>
												}
												placement="bottom">
												<Nav.Link
													href={`/notifications`}
													onClick={(e) =>
														pushHistory(
															e,
															"/notifications"
														)
													}>
													<span
														className={`material-icons ${
															[
																"unread",
																"new",
															].includes(
																props?.notificationBellStatus
															) &&
															window.location
																.pathname !==
																"/notifications"
																? props?.notificationBellStatus ===
																  "unread"
																	? "bell-unread"
																	: "bell-new"
																: ""
														}`}>
														notifications
													</span>
													<span
														className={`align-top bell-text ${
															[
																"unread",
																"new",
															].includes(
																props?.notificationBellStatus
															) &&
															window.location
																.pathname !==
																"/notifications"
																? "bell-unread"
																: ""
														}`}>
														&nbsp;Notifications
													</span>
												</Nav.Link>
											</OverlayTrigger>
										</Nav.Item>
										<Nav.Item>
											<OverlayTrigger
												overlay={
													<Tooltip
														id="members"
														className="d-none d-lg-block">
														Dashboard
													</Tooltip>
												}
												placement="bottom">
												<Nav.Link
													href="/dashboard"
													onClick={(e) =>
														pushHistory(
															e,
															"/dashboard"
														)
													}>
													<span
														className={`material-icons ${
															props.hasNewRewards &&
															window.location
																.pathname !==
																"/dashboard"
																? "new-rewards"
																: ""
														}`}>
														dashboard
													</span>
													<span
														className={`align-top d-lg-none ${
															props.hasNewRewards &&
															window.location
																.pathname !==
																"/dashboard"
																? "new-rewards"
																: ""
														}`}>
														&nbsp;Dashboard
													</span>
												</Nav.Link>
											</OverlayTrigger>
										</Nav.Item>
									</>
								)}
								{/* if user is logged in, display info */}
								{localUserToken ? (
									<>
										<Nav.Item>
											{/* large screens */}
											<span
												className="d-none d-lg-block"
												style={{
													paddingLeft: "20px",
													marginTop: "-5px",
												}}>
												<DropdownButton
													title={
														currentUserData?._id ? (
															<>
																<span
																	className="header-avatar-icon"
																	style={{
																		borderColor:
																			currentUserData?.icon_outline,
																	}}>
																	<img
																		alt={`${currentUserData?.username}'s Avatar`}
																		src={
																			props.currentUserAvatar ||
																			props
																				.images
																				.defaultAvatar
																		}
																		onError={(
																			e
																		) => {
																			// @ts-ignore
																			e.target.src =
																				props.images.defaultAvatar;
																		}}
																		className="header-avatar-icon__image"
																	/>
																</span>

																<span
																	className="align-middle"
																	style={{
																		height: "40px",
																		paddingLeft:
																			"40px",
																		paddingRight:
																			"5px",
																		maxWidth:
																			"20ch",
																		display:
																			"inline-block",
																		whiteSpace:
																			"nowrap",
																		overflow:
																			"hidden",
																		textOverflow:
																			"ellipsis",
																	}}>
																	<span
																		style={{
																			position:
																				"relative",
																			top: "7px",
																		}}>
																		{
																			currentUserData?.username
																		}
																	</span>
																</span>
															</>
														) : (
															<span>
																<i>
																	No session
																</i>
															</span>
														)
													}>
													{currentUserData?._id ? (
														<>
															<Dropdown.Item
																href={`/users/${currentUserData?._id}`}
																onClick={(e) =>
																	pushHistory(
																		e,
																		`/users/${currentUserData?._id}`
																	)
																}>
																<span className="material-icons">
																	account_box
																</span>
																<span className="align-top">
																	&nbsp;Profile
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() =>
																	setXpModalShow(
																		true
																	)
																}>
																<span className="material-icons yellow">
																	workspace_premium
																</span>
																<span className="align-top ">
																	&nbsp;Rank{" "}
																	{
																		currentUserData?.rank
																	}
																</span>
															</Dropdown.Item>
															{currentUserData?.isStaff && (
																<Dropdown.Item
																	href="/staff"
																	onClick={(
																		e
																	) =>
																		pushHistory(
																			e,
																			"/staff"
																		)
																	}>
																	<span className="material-icons">
																		build_circle
																	</span>
																	<span className="align-top">
																		&nbsp;Staff
																	</span>
																</Dropdown.Item>
															)}
															<Dropdown.Item
																href={`/levels/user/${currentUserData?._id}`}
																onClick={(e) =>
																	pushHistory(
																		e,
																		`/levels/user/${currentUserData?._id}`
																	)
																}>
																<span className="material-icons">
																	view_list
																</span>
																<span className="align-top">
																	&nbsp;My
																	Levels
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																href={`/levels/add`}
																onClick={(e) =>
																	pushHistory(
																		e,
																		`/levels/add`
																	)
																}>
																<span className="material-icons">
																	add_box
																</span>
																<span className="align-top">
																	&nbsp;Add a
																	level
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																href={`/customization/${currentUserData?._id}`}
																onClick={(e) =>
																	pushHistory(
																		e,
																		`/customization/${currentUserData?._id}`
																	)
																}>
																<span className="material-icons">
																	lightbulb
																</span>
																<span className="align-top">
																	&nbsp;Customization
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																href={`/account`}
																onClick={(e) =>
																	pushHistory(
																		e,
																		"/account"
																	)
																}>
																<span className="material-icons">
																	settings
																</span>
																<span className="align-top">
																	&nbsp;Settings
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																href="/#"
																onClick={(
																	e
																) => {
																	e.preventDefault();
																	e.stopPropagation();
																	props.setInfractionModalShow(
																		true
																	);
																}}>
																<span className="material-icons">
																	balance
																</span>
																<span className="align-top">
																	&nbsp;Infractions
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() =>
																	handleLogout()
																}>
																<span className="material-icons">
																	power_settings_new
																</span>
																<span className="align-top">
																	&nbsp;Sign
																	out
																</span>
															</Dropdown.Item>
														</>
													) : (
														<Dropdown.Item
															onClick={() =>
																window.location.reload()
															}>
															Refresh
														</Dropdown.Item>
													)}
												</DropdownButton>
											</span>

											{/* mobile screens */}
											<span className="d-lg-none">
												<DropdownButton
													title={
														currentUserData?._id ? (
															<>
																<span
																	className="header-avatar-icon"
																	style={{
																		borderColor:
																			currentUserData?.icon_outline,
																	}}>
																	<img
																		alt={`${currentUserData?.username}'s Avatar`}
																		src={
																			props.currentUserAvatar ||
																			props
																				.images
																				.defaultAvatar
																		}
																		onError={(
																			e
																		) => {
																			// @ts-ignore
																			e.target.src =
																				props.images.defaultAvatar;
																		}}
																		className="header-avatar-icon__image"
																	/>
																</span>

																<span
																	className="align-middle"
																	style={{
																		height: "40px",
																		paddingLeft:
																			"40px",
																		paddingRight:
																			"5px",
																		maxWidth:
																			"20ch",
																		display:
																			"inline-block",
																		whiteSpace:
																			"nowrap",
																		overflow:
																			"hidden",
																		textOverflow:
																			"ellipsis",
																	}}>
																	<span
																		style={{
																			position:
																				"relative",
																			top: "7px",
																		}}>
																		{
																			currentUserData?.username
																		}
																	</span>
																</span>
															</>
														) : (
															<span>
																<i>
																	No session
																</i>
															</span>
														)
													}>
													{currentUserData?._id ? (
														<>
															<Dropdown.Item
																href={`/users/${currentUserData?._id}`}
																onClick={(e) =>
																	pushHistory(
																		e,
																		`/users/${currentUserData?._id}`
																	)
																}>
																<span className="material-icons">
																	person
																</span>
																<span className="align-top">
																	&nbsp;Profile
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() =>
																	setXpModalShow(
																		true
																	)
																}>
																<span className="material-icons yellow">
																	workspace_premium
																</span>
																<span className="align-top">
																	&nbsp;Rank{" "}
																	{
																		currentUserData?.rank
																	}
																</span>
															</Dropdown.Item>
															{currentUserData?.isStaff && (
																<Dropdown.Item
																	href="/staff"
																	onClick={(
																		e
																	) =>
																		pushHistory(
																			e,
																			"/staff"
																		)
																	}>
																	<span className="material-icons">
																		build_circle
																	</span>
																	<span className="align-top">
																		&nbsp;Staff
																	</span>
																</Dropdown.Item>
															)}
															<Dropdown.Item
																href={`/levels/user/${currentUserData?._id}`}
																onClick={(e) =>
																	pushHistory(
																		e,
																		`/levels/user/${currentUserData?._id}`
																	)
																}>
																<span className="material-icons">
																	view_list
																</span>
																<span className="align-top">
																	&nbsp;My
																	Levels
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																href={`/levels/add`}
																onClick={(e) =>
																	pushHistory(
																		e,
																		`/levels/add`
																	)
																}>
																<span className="material-icons">
																	add_box
																</span>
																<span className="align-top">
																	&nbsp;Add a
																	level
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																href={`/customization/${currentUserData?._id}`}
																onClick={(e) =>
																	pushHistory(
																		e,
																		`/customization/${currentUserData?._id}`
																	)
																}>
																<span className="material-icons">
																	lightbulb
																</span>
																<span className="align-top">
																	&nbsp;Customization
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																href={`/account`}
																onClick={(e) =>
																	pushHistory(
																		e,
																		"/account"
																	)
																}>
																<span className="material-icons">
																	settings
																</span>
																<span className="align-top">
																	&nbsp;Settings
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																href="/#"
																onClick={(
																	e
																) => {
																	e.preventDefault();
																	e.stopPropagation();
																	props.setInfractionModalShow(
																		true
																	);
																}}>
																<span className="material-icons">
																	balance
																</span>
																<span className="align-top">
																	&nbsp;Infractions
																</span>
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() =>
																	handleLogout()
																}>
																<span className="material-icons">
																	power_settings_new
																</span>
																<span className="align-top">
																	&nbsp;Sign
																	out
																</span>
															</Dropdown.Item>
														</>
													) : (
														<Dropdown.Item
															onClick={() =>
																window.location.reload()
															}>
															Refresh
														</Dropdown.Item>
													)}
												</DropdownButton>
											</span>
										</Nav.Item>
									</>
								) : (
									// otherwise, display login / register button
									<>
										<Nav.Item>
											<Nav.Link
												href={`/auth?url=${window.location.pathname}`}
												onClick={(e) =>
													pushHistory(
														e,
														`/auth?url=${window.location.pathname}`
													)
												}>
												<button
													type="button"
													className="btn btn-primary btn-block"
													style={{
														marginTop: "-5px",
													}}>
													Login / Register
												</button>
											</Nav.Link>
										</Nav.Item>
									</>
								)}
							</ul>
						</div>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>
	);
};

export default Header;
