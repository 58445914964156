import React, { useContext, useEffect, useState } from "react";

// page styles
import "./style.scss";
import { useHistory } from "react-router-dom";

import gameBanner0 from "../../assets/images/GameBanner0.webp";
import gameBanner1 from "../../assets/images/GameBanner1.webp";
import gameBanner2 from "../../assets/images/GameBanner2.webp";
import gameBanner3 from "../../assets/images/GameBanner3.webp";
import gameBanner4 from "../../assets/images/GameBanner4.webp";
import { toast } from "react-toastify";
import { UserContext } from "../../App";

/**
 * The games page of the site, listing all the games you can use
 * to make levels.
 *
 * @returns The site's games page.
 */
export const Games = (props) => {
	useEffect(() => {
		document.title = "Games - Level Share Square";
	}, []);

	return (
		<div className="container mt-4">
			<div className="jumbotron gamepage-top">
				<h2
					className="display-4"
					style={{ textAlign: "center", width: "100%" }}>
					Select a game!
				</h2>
			</div>

			<div
				className="jumbotron"
				style={{
					borderTopRightRadius: "0",
					borderTopLeftRadius: "0",
					backgroundColor: "rgba(80, 17, 142, 0.2)",
				}}>
				<div className="game-list">
					{props.gameProperties?.gameNames?.map((_, index) => {
						return (
							<React.Fragment key={index}>
								<Game {...{ ...props, index }} />
							</React.Fragment>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export const Game = (props) => {
	const currentUserData = useContext(UserContext);
	const [transitioned, setTransitioned] = useState(true);
	const history = useHistory();
	const index = props.index;
	const gameAcronym = props?.gameProperties?.gameAcronyms?.[props.index];

	const gameBannerImages = [
		gameBanner0,
		gameBanner1,
		gameBanner2,
		gameBanner4,

		// keep smf at the end
		gameBanner3,
	];

	return (
		<div
			className="game-list__button game-list__transition"
			onMouseEnter={() => setTransitioned(true)}
			onMouseLeave={() => setTransitioned(false)}
			onTransitionEndCapture={() => setTransitioned(true)}
			style={{
				backgroundImage: `url(${gameBannerImages[index]})`,
				...(transitioned ? {} : { zIndex: "5" }),
			}}
			onClick={() => {
				// go to the level select corresponding to the game
				if (props.setGameParam) {
					const game = index === 3 ? 4 : index === 4 ? 3 : index;
					history.push(`/${gameAcronym}/levels`);
					if (props?.setCurrentUserData && currentUserData?._id)
						props.setCurrentUserData((prevState) => {
							let newSettings = prevState?.general_settings || [];
							newSettings[0] = game;
							return {
								...prevState,
								general_settings: newSettings,
							};
						});
					return props.setGameParam(game);
				}
				const url = props.gameProperties.gameUrls[index];
				// go to the game
				if (url) {
					if (!url.includes("https"))
						return history.push(
							props.gameProperties.gameUrls[index]
						);
					return (window.location.href = url);
				}
				toast.warn("This game isn't available yet, check back later!");
			}}>
			<h3 className="game-list__title">
				{props.gameProperties.gameNames[index]}
			</h3>
		</div>
	);
};
