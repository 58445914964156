import React from "react";

// map the highest role and/or apply a color based upon it
export const mapHighestRole = (userRoles, mode) => {
	const excludedRoles = ["Muted", "Rate restricted"];
	const rolesRankMap = {
		Leader: 9,
		Admin: 8,
		"LSS Developer": 7,
		Partner: 6,
		Moderator: 5,
		Mitten: 4,
		"Donator+": 3,
		Donator: 2,
		"Beta tester": 1,
		Banned: -1,
		Muted: -2,
		"Rate Restricted": -3,
		"Deactivated account": -4,
		"Archive account": -5,
	};

	const roleColors = {
		Leader: "#ff1919",
		Admin: "#1cde02",
		"LSS Developer": "#f0e630",
		Partner: "#c93ff3",
		Moderator: "#ffa500",
		Mitten: "#fac125",
		"Donator+": "#31f7f1",
		Donator: "#31f7f1",
		"Beta tester": "#ffb3ff",
		Banned: "#9c9c9c",
	};

	const cardColors = {
		Leader: "#ff191950",
		Admin: "#1cde0224",
		"LSS Developer": "#f0e63025",
		Partner: "#c93ff340",
		Moderator: "#ffa50040",
		Mitten: "#fac12530",
		"Donator+": "#31f7f140",
		Donator: "#31f7f140",
		"Beta tester": "#ffb3ff40",
		Banned: "#31f7f140",
	};

	if (userRoles && userRoles.length > 0) {
		const filteredRoles = userRoles?.filter(
			(role) => !excludedRoles.includes(role)
		);
		const filteredExistingRoles = filteredRoles?.filter((role) =>
			rolesRankMap?.hasOwnProperty(role)
		);

		if (filteredExistingRoles.length > 0) {
			const highestRole = filteredRoles.reduce((highest, role) => {
				return rolesRankMap[role] > rolesRankMap[highest]
					? role
					: highest;
			});

			if (mode === "color") return roleColors[highestRole]; // Return the color
			if (mode === "card") return cardColors[highestRole]; // Return the color
			if (mode === "role") return highestRole; // Return the role
		}
	}

	// darius.
	if (userRoles?.includes("Elder God") && mode === "role") return "Elder God";

	return mode === "color"
		? "#ffffff"
		: mode === "role"
		? "Member"
		: mode === "card" && "rgba(0,0,0,0.4)"; // Return a default color or role if no roles found
};

// sorts an array of roles
export const sortRoles = (userRoles) => {
	const rolePriority = {
		Leader: 1,
		Admin: 2,
		"LSS Developer": 3,
		Partner: 4,
		Moderator: 5,
		Mitten: 6,
		"Donator+": 7,
		Donator: 8,
		"Beta tester": 9,
		Banned: 10,
		Muted: 11,
		"Rate Restricted": 12,
	};
	const sortedRoles = userRoles?.sort(
		(a, b) => rolePriority[a] - rolePriority[b]
	);
	return sortedRoles;
};

// displayrole styles with fade-in animation
//! pass prop in the same manner as done on profile/hoverusercard component
export const displayRoleStyle = (props) => {
	let opacity;

	// use transition style
	if (props?.showContent === true || props?.showContent === false)
		opacity = { opacity: props.showContent ? "1" : "0" };

	// use loop value
	if (props?.loadValue !== undefined)
		opacity = { opacity: (props.loadValue - 50) / 50 };

	// return the styles
	return {
		...opacity,
		transition: "opacity 0.6s ease-in-out",
		paddingLeft: "6px",
		paddingRight: "6px",
		marginRight: "6px",
		position: "relative",
	};
};

export const punishmentColor = (punishments) => {
	const infractions = ["Muted", "Rate Restricted", "Banned", "Warning"];
	// return if no punishments match up
	if (punishments.some((punishment) => !infractions.includes(punishment)))
		return <span>- Error -</span>;
	// mute & rate restrict
	if (punishments?.length > 1)
		return (
			<>
				<span className="gray">Muted</span> &{" "}
				<span className="gloamvitiation-orange">Rate Restricted</span>
			</>
		);
	// muted
	if (punishments?.includes("Muted"))
		return <span className="gray">{punishments[0]}</span>;
	// banned
	if (punishments?.includes("Banned"))
		return <span className="hydrawisp-red">{punishments[0]}</span>;
	// rate restricted
	if (punishments?.includes("Rate Restricted"))
		return <span className="gloamvitiation-orange">{punishments[0]}</span>;
	// warned
	if (punishments?.includes("Warning"))
		return <span className="yellow">{punishments[0]}</span>;
};
