import presetHTML5 from "@bbob/preset-html5";

const tagAttr = (style) => ({
	attrs: {
		style,
	},
});
const BBCodeParser = presetHTML5.extend((tags) => {
	return {
		...tags,
		b: (...args) => ({
			...tags.b(...args),
			...tagAttr({
				fontWeight: "bold",
			}),
		}),
		i: (...args) => ({
			...tags.i(...args),
			...tagAttr({
				fontStyle: "italic",
			}),
		}),
		u: (...args) => ({
			...tags.u(...args),
			...tagAttr({
				textDecoration: "underline",
			}),
		}),
		s: (...args) => ({
			...tags.s(...args),
			...tagAttr({
				textDecoration: "line-through",
			}),
		}),
		color: (attrs, ...children) => ({
			...tags.color(attrs, ...children),
			...tagAttr({
				// @ts-ignore
				color: Object?.values(Object?.values(attrs)?.[1])?.[0],
			}),
		}),
		colour: (attrs, ...children) => ({
			...tags.color(attrs, ...children),
			...tagAttr({
				// @ts-ignore
				color: Object?.values(Object?.values(attrs)?.[1])?.[0],
			}),
		}),
	};
});

export default BBCodeParser;
