// imports
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DefaultAvatar from "../../../assets/images/DefaultAvatar.webp";

// user context import
import { UserContext } from "../../../App";

// components
import Pagination from "../../Pagination/Pagination";
import { getMembersPage } from "../../../api";

// page styles
import "./style.scss";

// react-bootstrap components used
import { Card, Container, Jumbotron, Spinner } from "react-bootstrap";
import { mapHighestRole } from "../../../functions/styleAppliers";

// get all url parameters
const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

/**
 * Display a list of members.
 *
 * @returns
 */
const Members = (props) => {
	const currentUserData = useContext<any>(UserContext);

	// state info
	const [members, setMembers] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [membersChanged, setMembersChanged] = useState(false);
	const [hasDoneQuery, setHasDoneQuery] = useState(false);
	const [isQuerying, setIsQuerying] = useState(false);
	const [userMessage, setUserMessage] = useState("");
	const [numberOfPages, setNumberOfPages] = useState(0);

	const [searchQuery, setSearchQuery] = useState("");
	const [showSearchButton, setShowSearchButton] = useState(true);

	// hook initializations
	const query = useQuery();
	const history = useHistory();

	// url parameters
	const [previousPage, setPreviousPage] = useState<any>(null);
	const page =
		parseInt(query.get("page") ?? "0") > 0
			? parseInt(query.get("page") ?? "0")
			: 1;
	const memberQueryParam = query.get("memberQuery");

	useEffect(() => {
		document.title = "Members - Level Share Square";

		// cleanup
		return () => {
			setMembersChanged(false);
			setMembers([null]);
		};
	}, []);

	// keep url parameter and state info in sync for searches
	useEffect(() => {
		if (memberQueryParam && searchQuery !== memberQueryParam) {
			setSearchQuery(memberQueryParam);
			setShowSearchButton(false);
		}
		// eslint-disable-next-line
	}, [memberQueryParam]);

	// retrieve all member info
	useEffect(() => {
		// prevent duplicate requests
		if (previousPage === page) setMembersChanged(false);

		// fetch the current page of members
		if (
			(membersChanged === true || hasDoneQuery === false) &&
			isQuerying === false
		) {
			setIsQuerying(true);
			setMembersChanged(false);
			getMembersPage({
				page,
				searchQuery: searchQuery ?? "",
			}).then((response) => {
				setTotal(response?.data?.total);
				setHasDoneQuery(true);
				setIsQuerying(false);
				setUserMessage(response?.data?.message);
				setPreviousPage(page);
				setMembers(response?.data?.users || []);
				setNumberOfPages(response?.data?.numberOfPages || 1);
			});
		}
	}, [
		membersChanged,
		currentUserData?._id,
		page,
		previousPage,
		searchQuery,
		hasDoneQuery,
		isQuerying,
	]);

	// remove loading spinner if no members are found
	useEffect(() => {
		const spinner = document.getElementById("loading-spinner");
		if (spinner && userMessage) spinner.remove();
	}, [userMessage]);

	const handleKeyPress = (e) => {
		/* if the user presses the enter key (key code 13),
		 * search the same as if the search button were pressed */
		if (e.keyCode === 13) searchOrClearMembers(e);
	};

	const searchOrClearMembers = (e) => {
		e.preventDefault();

		// handle search button
		if (showSearchButton) {
			// handle fields not being properly filled out
			if (searchQuery === "")
				toast.error("Please specify a member search query!");
			else {
				setShowSearchButton(false);
				setMembersChanged(true);
				history.push(
					`${props.location.pathname}?memberQuery=${searchQuery}`
				);
			}
			// handle clear button
		} else {
			setSearchQuery("");
			setShowSearchButton(true);
			setMembersChanged(true);
			history.push(props.location.pathname);
		}
	};

	/**
	 * Display all members on the site.
	 *
	 * @returns
	 */
	const displayMembers = () => {
		// if there are no members, say so
		return !members?.length || membersChanged ? (
			// loading spinner
			<div>
				{membersChanged && (
					<div
						className="d-flex justify-content-center"
						id="loading-spinner">
						<Spinner animation="grow" variant="primary" />
					</div>
				)}

				{/* display "no members found" message if none are found */}
				{userMessage && !members?.length && (
					<div className="col-12">
						<div
							className="card"
							id="infoCard"
							style={{ textAlign: "center" }}>
							<div className="card-body" id="infoCard">
								<h2 className="card-title">{userMessage}</h2>
							</div>
						</div>
					</div>
				)}
			</div>
		) : (
			<>
				{""}
				{/* otherwise, print all the needed members */}
				<div className="members-list">
					<div className="members-list__members-wrapper">
						{members?.map((member) => {
							// toggle the copy symbol appearing
							const handleCopyHover = (mode) => {
								setMembers((prevState) =>
									prevState.map((user) => {
										if (user?._id === member?._id)
											return {
												...member,
												CopySymbol: mode,
											};
										return user;
									})
								);
							};

							// compare dates to check if the user is online
							const currentTime = new Date();
							const lastOnlineTime = new Date(member?.lastOnline);
							currentTime.setMinutes(
								currentTime.getMinutes() - 8
							);

							const isNowOnline =
								currentTime.getTime() -
									lastOnlineTime.getTime() <
								15 * 60 * 1000;

							return (
								<div key={member?._id}>
									<a
										href={`/users/${member?._id}`}
										onClick={(e) => {
											e.preventDefault();
											history.push(
												`/users/${member?._id}`
											);
										}}
										style={{ height: "fit-content" }}>
										<Card
											className="member hover-colour"
											style={{
												boxShadow: `0 0 45px 7px ${mapHighestRole(
													member?.mainRoles,
													"card"
												)}`,
												borderColor: mapHighestRole(
													member?.mainRoles,
													"card"
												),
											}}>
											<Card.Body className="member__card-body">
												<Card.Title
													className="member__username"
													title={member?.username}>
													<b>{member?.username}</b>
												</Card.Title>
												<Card.Subtitle className="member__rank">
													<span className="yellow">
														Rank {member?.rank || 1}
													</span>
												</Card.Subtitle>
												<Card.Subtitle className="member__followers-count">
													Followers:{" "}
													{member?.followers || 0}
												</Card.Subtitle>
												<Card.Subtitle className="member__levelcomments-count">
													Reviews:{" "}
													{member?.level_comments ||
														0}
												</Card.Subtitle>
												<Card.Subtitle className="member__levels-count">
													Levels:{" "}
													{member?.levels || 0}
												</Card.Subtitle>
												<Card.Subtitle className="member__rates-count">
													Rates: {member?.rates || 0}
												</Card.Subtitle>
												<div
													style={{
														margin: "0 auto",
														position: "absolute",
														width: "max-content",
														top: "20%",
														right: "16px",
													}}>
													<div
														className="member__user-icon"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															toast.success(
																"Copied user ID!"
															);
															navigator.clipboard.writeText(
																member?._id
															);
														}}
														style={{
															borderColor:
																mapHighestRole(
																	member?.mainRoles,
																	"color"
																),
															boxShadow: `0 0 3px 2px ${mapHighestRole(
																member?.mainRoles,
																"color"
															)}`,
														}}>
														<img
															alt="User Avatar"
															src={
																member?.avatar ||
																DefaultAvatar
															}
															onError={(e) => {
																// @ts-ignore
																e.target.src =
																	DefaultAvatar;
															}}
															className="member__user-icon__image"
														/>
														<div className="avatar-icon__image">
															<span
																className="material-icons avatar-icon__link"
																onMouseEnter={() =>
																	handleCopyHover(
																		true
																	)
																}
																onMouseLeave={() =>
																	handleCopyHover(
																		false
																	)
																}
																style={{
																	opacity:
																		member?.CopySymbol ===
																		true
																			? "1"
																			: "0",
																}}>
																content_copy
															</span>
														</div>
													</div>
													<div
														className="member__roles main-roles"
														style={{
															width: "max-content",
															margin: "0 auto",
															marginTop: "8px",
															color: mapHighestRole(
																member?.mainRoles,
																"color"
															),
															borderColor:
																mapHighestRole(
																	member?.mainRoles,
																	"color"
																),
															boxShadow: `0 0 1px 1px ${mapHighestRole(
																member?.mainRoles,
																"color"
															)}`,
														}}>
														<div
															style={{
																paddingLeft:
																	"6px",
																paddingRight:
																	"6px",
															}}>
															{member?.mainRoles
																.length > 0
																? mapHighestRole(
																		member.mainRoles,
																		"role"
																  )
																: member?.verified ===
																  true
																? "Member"
																: "Unverified User"}
														</div>
													</div>
												</div>
												<div className="member__joined-on">
													Joined on
													<span className="member__joined-on-date blue">
														{moment(
															member?.joinDate
														).format("MMM D, YYYY")}
													</span>
												</div>
												<div className="member__last-online">
													{isNowOnline ? (
														<>
															Last online
															<span className="member__last-online-date blue">
																NOW
															</span>
														</>
													) : (
														<>
															Last online on
															<span className="member__last-online-date blue">
																{moment(
																	member?.lastOnline
																).format(
																	"MMM D, YYYY"
																)}
															</span>
														</>
													)}
												</div>
											</Card.Body>
										</Card>
									</a>
								</div>
							);
						})}
					</div>

					{/* allow user to change member page, modifiable pagination component is in Pagination.js */}
					<div className="MuiPagination-root">
						<Pagination
							setContentChanged={setMembersChanged}
							page={page}
							numberOfPages={numberOfPages}
							type="comment"
							origin="memberspage"
							siblingCount={3}
						/>
					</div>
				</div>
			</>
		);
	};

	return (
		<Container className="members-header mt-4">
			<Jumbotron className="members-header__jumbotron">
				<h2 className="display-4">Browse Members</h2>
				<p className="lead">
					You can browse all the site members on this page!{" "}
					{isQuerying === false && membersChanged === false && (
						<span className="yellow">
							({total || "none"} found)
						</span>
					)}
				</p>

				{/* member search functionality */}
				<form onSubmit={searchOrClearMembers}>
					<div className="form-group">
						<div className="input-group mb-3">
							{/* filter by member name */}
							<input
								type="text"
								className="form-search-control"
								id="search"
								name="search"
								maxLength={255}
								placeholder="Search for members..."
								value={searchQuery}
								onKeyDown={handleKeyPress}
								onChange={(e) => {
									if (!showSearchButton)
										setShowSearchButton(true);
									setSearchQuery(e.target.value);

									// handle search input being cleared manually
									if (e.target.value === "") {
										setMembersChanged(true);
										history.push(props.location.pathname);
									}
								}}
							/>
							<OverlayTrigger
								overlay={
									<Tooltip id="searchActions">
										{showSearchButton
											? "Search members"
											: "Reset search"}
									</Tooltip>
								}
								placement="top">
								<button
									className="btn btn-primary"
									type="button"
									onClick={searchOrClearMembers}
									id="search-button">
									<span className="material-icons align-bottom">
										{showSearchButton ? "search" : "close"}
									</span>
								</button>
							</OverlayTrigger>
						</div>
					</div>
				</form>
				<br />
				<br />
				{/* only display button to join the site if the user is not logged in */}
				{!currentUserData && (
					<div className="members-header__login-button-wrapper">
						<a
							href={`/auth?url=${window.location.pathname}`}
							onClick={(e) => {
								e.preventDefault();
								history.push(
									`/auth?url=${window.location.pathname}`
								);
							}}>
							<button
								type="button"
								className="btn btn-primary btn-lg btn-block members-header__login-button">
								Login / Register
							</button>
						</a>
					</div>
				)}
			</Jumbotron>

			{/* display all needed members */}
			<div className="row mx-auto">{displayMembers()}</div>
		</Container>
	);
};

export default Members;
