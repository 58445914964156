// react imports
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// components
import { getAboutPage } from "../../api";

import { mapHighestRole } from "../../functions/styleAppliers";

/**
 * Page containing information about the community, site, and
 * people responsible for it.
 *
 * @returns The site's about page.
 */
const About = (props) => {
	const history = useHistory();

	const pushHistory = (e) => {
		e.preventDefault();
		// uhhh... yea
		const fullUrl = e.target.href;
		const origin = e.target.host;
		// construct href
		const href = fullUrl.split(origin).slice(1).join("");
		// navigate
		history.push(href);
		window.scrollTo(0, 0);
	};
	// state info for users
	const initialUsers: {
		admin: any[];
		moderator: any[];
		mitten: any[];
		developer: any[];
		partner: any[];
	} = {
		admin: [],
		moderator: [],
		mitten: [],
		developer: [],
		partner: [],
	};
	// state info for user messages
	const initialMessages: {
		admin: string;
		moderator: string;
		mitten: string;
		developer: string;
		partner: string;
	} = {
		admin: "",
		moderator: "",
		mitten: "",
		developer: "",
		partner: "",
	};
	// set states
	const [displayedUsers, setDisplayedUsers] = useState(initialUsers);
	const [userMessages, setUserMessages] = useState(initialMessages);
	const [partnersToGames, setPartnersToGames] = useState<Map<string, string>>(
		new Map()
	);

	useEffect(() => {
		document.title = "About Us - Level Share Square";
	});

	// retrieve all user info
	useEffect(() => {
		// fetch all needed users for about page
		getAboutPage().then((response) => {
			const data = response?.data;
			if (data) {
				// define roles
				const rolesLowercase = [
					"mitten",
					"moderator",
					"partner",
					"developer",
					"admin",
					"leader",
				];
				rolesLowercase?.forEach((role) => {
					if (data?.[role]?.length) {
						// update state
						setDisplayedUsers((prevState) => {
							return {
								...prevState,
								[role]: data?.[role],
							};
						});
						return; // next
					}
					// fallback
					setUserMessages((prevState) => {
						return {
							...prevState,
							[role]: "No users found with this role.",
						};
					});
					setDisplayedUsers((prevState) => {
						return {
							...prevState,
							[role]: [],
						};
					});
				});
				// mapping of partners to their game(s)
				const partnersMap = new Map();
				// iterate over the returned partners to create a mapping
				if (
					data?.partner?.length &&
					props.gameProperties.gameNames?.length !== 0
				)
					data?.partner?.forEach((partner) => {
						// associate the current partner with their game(s), (hard coded for now)
						switch (partner.username) {
							case "Dignity":
								partnersMap.set(
									partner.username,
									props.gameProperties.gameNames[2]
								);
								break;
							case "Rovertronic":
								partnersMap.set(
									partner.username,
									props.gameProperties.gameNames[3]
								);
								break;
							default:
						}
					});
				// set new mapping
				return setPartnersToGames(partnersMap);
			}
		});
	}, [props.gameProperties.gameNames]);

	// generate an entry in an unordered list of users
	const generateUserListEntry = (user, key, message?) => {
		return (
			<li
				key={key}
				style={{
					paddingBottom: "20px",
				}}>
				<div
					className="member__roles main-roles"
					style={{
						float: "left",
						width: "max-content",
						margin: "0 auto",
						marginTop: "-5px",
						marginRight: "10px",
						color: mapHighestRole(user?.mainRoles, "color"),
						borderColor: mapHighestRole(user?.mainRoles, "color"),
						boxShadow: `0 0 1px 1px ${mapHighestRole(
							user?.mainRoles,
							"color"
						)}`,
					}}>
					<div
						style={{
							paddingLeft: "6px",
							paddingRight: "6px",
						}}>
						{user?.mainRoles.length > 0
							? mapHighestRole(user.mainRoles, "role")
							: user?.verified === true
							? "Member"
							: "Unverified User"}
					</div>
				</div>
				<a onClick={(e) => pushHistory(e)} href={`/users/${user?._id}`}>
					{user?.username}
				</a>
				{/* include message if one is present */}
				{message && (
					<>
						{" "}
						<b>{message}</b>
					</>
				)}
			</li>
		);
	};

	return (
		<div className="container mt-4">
			<div className="col-12 col-lg-9 mx-auto">
				{/* About LSS */}
				<div className="card">
					<div className="card-header">
						<img
							src={props.images.Exclamation}
							alt="Exclamation"
							width="48"
							height="48"
						/>
						<span
							className="align-middle"
							style={{ paddingLeft: "7px", fontSize: "36px" }}>
							About Level Share Square
						</span>
					</div>
					<div className="card-body">
						<h2>Who we are:</h2>
						<p>
							We are a group of hobbyist level creators that enjoy
							making custom levels in different level editors—the
							original community of which spans over a decade.
							Level Share Square serves to foster an environment
							for these games, users, and any levels that they
							create. If you would like to partner with us in
							order to have your game be supported and featured on
							our site, please{" "}
							<a onClick={(e) => pushHistory(e)} href="/contact">
								contact us
							</a>
							.
						</p>

						<h2>Who are our staff members?</h2>
						<div>
							<u>
								The following are the Level Share Square admins:
							</u>
							{userMessages?.admin ? (
								<>
									<br />
									<i>{userMessages?.admin}</i>
									<br />
									<br />
								</>
							) : displayedUsers?.admin?.length === 0 ? (
								<>
									<br />
									<i>Loading admins...</i>
									<br />
									<br />
								</>
							) : (
								<ul
									className="toc_list"
									style={{ paddingTop: "10px" }}>
									{/* list all current site admins */}
									{displayedUsers?.admin?.map(
										(admin, key) => {
											return generateUserListEntry(
												admin,
												key
											);
										}
									)}
								</ul>
							)}
							<u>
								The following are the Level Share Square
								moderators:
							</u>
							{userMessages?.moderator ? (
								<>
									<br />
									<i>{userMessages?.moderator}</i>
									<br />
									<br />
								</>
							) : displayedUsers?.moderator?.length === 0 ? (
								<>
									<br />
									<i>Loading moderators...</i>
									<br />
									<br />
								</>
							) : (
								<ul
									className="toc_list"
									style={{ paddingTop: "10px" }}>
									{/* list all current site moderators */}
									{displayedUsers?.moderator?.map(
										(moderator, key) => {
											return generateUserListEntry(
												moderator,
												key
											);
										}
									)}
								</ul>
							)}
							<u>
								The following are the Level Share Square
								M.I.T.T.E.N.s (moderators in training):
							</u>
							{userMessages?.mitten ? (
								<>
									<br />
									<i>{userMessages?.mitten}</i>
									<br />
									<br />
								</>
							) : displayedUsers?.mitten?.length === 0 ? (
								<>
									<br />
									<i>Loading M.I.T.T.E.N.s...</i>
									<br />
									<br />
								</>
							) : (
								<ul
									className="toc_list"
									style={{ paddingTop: "10px" }}>
									{/* list all current site mittens */}
									{displayedUsers?.mitten?.map(
										(mitten, key) => {
											return generateUserListEntry(
												mitten,
												key
											);
										}
									)}
								</ul>
							)}
							<p>
								Feel free to contact any of the above staff
								members if you need any assistance, but keep in
								mind that admins have more site permissions than
								moderators and M.I.T.T.E.N.s, and that
								M.I.T.T.E.N.s are not full moderators, but only
								those that are still in training, and are
								therefore not permanent additions to the site's
								staff team.
							</p>
						</div>

						<h2>Who made the site?</h2>
						<div>
							<u>
								Level Share Square was created with passion by
								the following main developers:
							</u>
							{userMessages?.developer ? (
								<>
									<br />
									<i>{userMessages?.developer}</i>
									<br />
									<br />
								</>
							) : displayedUsers?.developer?.length === 0 ? (
								<>
									<br />
									<i>Loading developers...</i>
									<br />
									<br />
								</>
							) : (
								<ul
									className="toc_list"
									style={{ paddingTop: "10px" }}>
									{/* list all current site developers */}
									{displayedUsers?.developer?.map(
										(developer, key) => {
											return generateUserListEntry(
												developer,
												key
											);
										}
									)}
								</ul>
							)}
							<p>
								Each of them are responsible for different parts
								and functionality of the site, but together they
								have created the majority of the codebase for
								Level Share Square in its entirety. If you
								encounter an issue with the site, you can report
								it to one of them.
							</p>
						</div>

						<h2>Who do we work with?</h2>
						<div>
							<u>
								The following are our partners, who develop
								game(s) for the site that we offer and support:
							</u>
							{userMessages?.partner ? (
								<>
									<br />
									<i>{userMessages?.partner}</i>
									<br />
									<br />
								</>
							) : displayedUsers?.partner?.length === 0 ||
							  partnersToGames.size === 0 ? (
								<>
									<br />
									<i>Loading partners...</i>
									<br />
									<br />
								</>
							) : (
								<ul
									className="toc_list"
									style={{ paddingTop: "10px" }}>
									{/* list all current partners */}
									{displayedUsers?.partner?.map(
										(partner, key) => {
											return (
												// also list the current game(s) that the partner develops
												generateUserListEntry(
													partner,
													key,
													`(Developer of ${partnersToGames.get(
														partner?.username
													)})`
												)
											);
										}
									)}
								</ul>
							)}
							<p>
								We continually work with these users to
								coordinate and ensure support for their games on
								Level Share Square into the future.
							</p>
						</div>

						<div>
							<br />
							<p>
								Special thanks to{" "}
								<a
									onClick={(e) => pushHistory(e)}
									href="/users/6460617c050bf70339509ff0"
									style={{ color: "#ff0000" }}>
									Popthatcorn14
								</a>{" "}
								for helping keep the site running ad-free by
								being our #1 donator and putting his passion
								into the community.
							</p>
							<u>
								The following people have also contributed
								assets and/or services to the site, such as
								small bits of code, graphics, or performed beta
								testing:
							</u>
							<ul className="toc_list">
								<li>Entiss</li>
								<li>Flarewire</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/users/645fb767050bf703394f9306">
										Tristaph
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/users/645fb73d050bf703394f9204">
										Vortoxium
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/users/645fb790050bf703394f93e6">
										BlazingDragon
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/users/645fb840050bf703394f97e5">
										validusername16
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/users/645fdc21050bf70339501f9c">
										MistriuslySerious
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/users/645fe757050bf70339503428">
										Solidfire
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/users/6460475d050bf70339509549">
										Markey97
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/users/646055aa050bf70339509af2">
										bagel the fox
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/users/64d995bec487392eae99e4d8">
										Sternosaur
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/users/652764eab239ad06fff9187a">
										Rovertronic
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/users/646108ecb15a87574f4feaeb">
										Niplob
									</a>
								</li>
							</ul>
						</div>

						<h2>Games we currently offer:</h2>
						<div>
							<u>
								We currently offer and support the following
								games:
							</u>
							{props.gameProperties.gameNames?.length === 0 ? (
								<>
									<br />
									<i>No games found!</i>
									<br />
									<br />
								</>
							) : (
								<ul className="toc_list">
									{/* list all current site games */}
									{props.gameProperties.gameNames?.map(
										(game, key) => {
											return (
												<li key={key}>
													<a
														href={
															props.gameProperties
																.gameUrls[key]
														}>
														{game}
													</a>
													{game ===
														"Super Mario Flash" && (
														<>
															{" "}
															<b>
																(Not supported
																for newly posted
																levels)
															</b>
														</>
													)}
												</li>
											);
										}
									)}
								</ul>
							)}
							<p>
								We are always open to accepting new games for
								our site, so if you would be interested in
								partnering with us and having your game listed
								and supported on Level Share Square, please{" "}
								<a
									onClick={(e) => pushHistory(e)}
									href="/contact">
									get in touch
								</a>
								.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
