import React, { useEffect, useState } from "react";

// page styles
import "./style.scss";

// ------[Adding a new question]------ //
// Each question has a category ID and question ID, labelled as e.g. C2Q1 for the first question in category 2.
// These need to be applied to the following properties: id, data-bs-target, aria-controls & aria-labelledby

/**
 * The FAQ page of the site, going over and explaining several of its features/functionality.
 *
 * @returns The site's FAQ page.
 */
const FAQ = (props) => {
	useEffect(() => {
		document.title = "FAQ - Level Share Square";
	}, []);

	const FaqItems = [
		{
			header: "Creating, saving & sharing levels",
			content: {
				questions: [
					"How do I save a level to my account?",
					"How do I share a level?",
					"How do I create a thumbnail?",
					"Can I edit my levels?",
				],
				answers: [
					"Simply click on the <a href='/levels/add'>Add a level</a> button which you can find on the homepage or in the dropdown bar in the top right. You must be logged into an LSS account to be able to save levels to the site.",
					"To share a level, simply set the visibility of a private level to <b>public</b> on the add/edit level page. When adding a new level, you can also make it public directly.",
					"Thumbnails aren't required to post your level, but nice to have! <br />You can add a thumbnail to your level on the <a href='/levels/add'>add/edit level page</a>.<br />Some games offer tools to allow you to easily add a thumbnail to your level.<br /><br /><b>Super Mario Construct:</b><br />While playtesting your level, press the toolbox at the bottom-left, and click on the camera button.<br />A screenshot will be taken that you can save to your computer, ready to upload to the site!<br /><br /> <b>Yoshi's Fabrication Station:</b><br /> While playtesting your level, press T on your keyboard to take a screenshot that'll automatically save to your computer.",
					"Yes! You can edit your own level at any time, including making it private.<br />The only restriction is that you can't replace the level code with that of a different game.",
				],
			},
		},
		{
			header: "Account information",
			content: {
				questions: [
					"Why can't I rate or comment on levels?",
					"How can I verify my account?",
				],
				answers: [
					"There are several reasons why you might not be able to rate or comment on someone's level, or simply can't interact with certain site features, here are the possible reasons:<br /><br /><b>You're not logged into an LSS account</b><br />You need a valid LSS account (which hasn't been banned from the site) to perform these actions. Make sure you've <a href='/verify'>verified</a> your account as well.<br /><br /><b>You haven't verified your LSS account yet</b><br />Unverified users can only save their levels, but not rate/comment/share/etc.<br /><br /><b>You've been rate restricted</b><br />Rate restricted users cannot rate levels by	any means. This punishment is applied by staff.<br /><br /><b>You've been muted</b><br />Muted users can't comment on profiles or levels. This punishment is also applied by staff.<br />This may be escalated into a ban if media posted on the site is used to bypass it.",
					"Verification on LSS is done through email, you can do this on the <a href='/verify'>verification page</a>.<br /> Secondary verification can be achieved through the S.H.A.R.E. bot on Discord, which can be done on the Settings page, make sure to provide your Discord ID, which is a string of numbers.",
				],
			},
		},
		{
			header: "Miscellaneous",
			content: {
				questions: [
					"How do I create an account?",
					"What did you use to develop the Level Share Square website?",
					"Where can I get involved with the community?",
				],
				answers: [
					"You may create an account by going to the <a href='/auth'>registration page </a> and clicking on 'Register'. Please do note that terms apply under account creation, such as being limited to one per person and having to be at least 13 years old.",
					"The Level Share Square frontend is built using <b>ReactJS</b>, with the backend using <b>NodeJS</b> with <b>Express</b>, and our database is powered by <b>MongoDB</b>.",
					"We're happy to get to know you! If you want to get involved with the site's community, we'd recommend joining the <a href='https://discord.gg/skRNawRm8y'>Level Share Square Discord</a>.<br /><br />If you'd like to join the community surrounding a specific game on the site, here's a handy list:<br /><br />Super Mario Construct: <a href='https://discord.gg/NXjVVmT'>https://discord.gg/NXjVVmT</a><br />Yoshi's Fabrication Station: <a href='https://discord.gg/cFxDZB9ptf'>https://discord.gg/cFxDZB9ptf</a><br />Super Mario 127: <a href='https://discord.gg/qgfErCy'>https://discord.gg/qgfErCy</a><br />Mario Builder 64: <a href='https://discord.gg/X7vvvKu'>https://discord.gg/X7vvvKu</a>",
				],
			},
		},
	];

	return (
		<div className="container mt-4">
			<div className="col-12 col-lg-9 mx-auto">
				{/* Top card, containing main info for the FAQ page */}
				<div className="card">
					<div className="card-header">
						<img
							src={props.images.Exclamation}
							alt="Exclamation"
							width="48"
							height="48"
						/>
						<span
							className="align-middle"
							style={{ paddingLeft: "7px", fontSize: "36px" }}>
							Frequently Asked Questions
						</span>
					</div>
					<div className="card-body">Welcome to the FAQ page!</div>
				</div>
				<br />

				{/* Level-related questions & answers */}
				{FaqItems.map((item, index) => {
					return (
						<span key={index}>
							<div className="card">
								<div className="card-header">
									<span
										className="align-middle"
										style={{
											paddingLeft: "7px",
											fontSize: "26px",
										}}>
										{item?.header}
									</span>
								</div>
								<div className="card-body">
									{item?.content?.questions?.map(
										(content, key) => (
											<span key={key}>
												<QuestionAndAnswer
													question={content}
													answer={
														item?.content?.answers[
															key
														]
													}
												/>
											</span>
										)
									)}
								</div>
							</div>
							<br />
						</span>
					);
				})}
			</div>
		</div>
	);
};

const QuestionAndAnswer = ({ question, answer }) => {
	const [isActive, setIsActive] = useState(false);
	const [fade, setFade] = useState(0);
	const [mouseOver, setMouseOver] = useState(false);

	const handleClick = (e) => {
		e.preventDefault();
		setIsActive((prevState) => !prevState);
	};

	// fadeout
	useEffect(() => {
		if (isActive && fade < 20)
			setTimeout(() => {
				setFade((prevState) => prevState + 1);
			}, 10);
		if (!isActive && fade > 0) {
			setFade(1);
			setTimeout(() => {
				setFade(0);
			}, 750);
		}
	}, [isActive, fade]);

	return (
		<>
			<a
				href="#!"
				onClick={(e) => handleClick(e)}
				onMouseEnter={() => setMouseOver(true)}
				onMouseLeave={() => setMouseOver(false)}>
				<h2
					style={{
						color: mouseOver ? "#a0f3e1" : "#32c7d1",
						transition: "color 0.65s ease-in-out",
					}}>
					{question}
				</h2>
			</a>
			{!isActive && fade === 0 ? null : (
				<div
					style={{
						opacity: fade / 20,
						transition: "opacity 0.75s ease-in-out",
					}}
					dangerouslySetInnerHTML={{ __html: answer }}
				/>
			)}
			<br />
		</>
	);
};

export default FAQ;
