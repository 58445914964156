import axios from "axios";
import { toast } from "react-toastify";
import mitt from "mitt";

// base api url for fetching information, which can be appended to
const api = axios.create({ baseURL: "/api" });

//! set up token for auth middleware
api.interceptors.request.use((req) => {
	const sessionName = "LSS_login_session";
	const token = localStorage.getItem(sessionName);

	// create bearer token
	if (token) req.headers.authorization = `Bearer ${token}`;

	return req;
});

// define an emitter for user ran states
export const rankEmitter = mitt();

//! interceptor for error
const errorHandler = (error) => {
	// handle an error
	if (error.response.status === 440) {
		// return response
		if (error.response.config.url === "/users/get_id_from_token")
			throw error;
		// logout if session expired
		if (error.response.data.toastMessage)
			toast.error(error.response.data.toastMessage);
		// clear session
		setTimeout(() => {
			localStorage.removeItem("LSS_login_session");
			window.location.reload();
			sessionStorage.clear();
		}, 4000);
		throw error;
	}
	if (error.response.status === 429) {
		toast.error(error.response.data);
		throw error;
	}
	if (error.response.data.toastMessage)
		toast.error(error.response.data.toastMessage);

	// Re-throw the error to propagate it to the caller
	throw error;
};

//! interceptor for response
const responseHandler = (response) => {
	// used for updating rank states globally
	if (response?.data?.rankResult?.rankCalc === true)
		rankEmitter.emit("response", response?.data?.rankResult);
	// on click for toast messages
	if (response.data.onClick !== undefined && response.data.toastMessage) {
		toast.success(response.data.toastMessage, {
			onClick: () => {
				window.location.href = response.data.onClick;
			},
		});
		return response;
	}

	if (response.data.toastMessage) toast.success(response.data.toastMessage);
	return response;
};

//* Set up response interceptor to catch errors for all requests
api.interceptors.response.use(
	(response) => responseHandler(response),
	(error) => errorHandler(error)
);

//!-----------------//
//! --- account --- //
//!-----------------//

// --- customization --- //
export const changeTheme = (contentID, themeData) =>
	api.patch(
		`/account/${contentID}/customization/theme?type=account`,
		themeData
	);
export const executeCrafting = (contentID, themeData) =>
	api.patch(
		`/account/${contentID}/customization/craft/${themeData?.type}/${themeData?.value}?type=account`
	);
export const changeAvatar = (contentID, avatarData) => {
	// regular
	if (avatarData?.field === "text")
		return api.patch(
			`/account/${contentID}/customization/avatar?type=account`,
			avatarData
		);
	const file = avatarData?.avatar;

	const imageData = new FormData();
	imageData.append("image", file);
	// image
	return api
		.post(`/accesspoint/image/upload/${contentID}?type=account`, imageData)
		.then((response) => {
			avatarData.avatar = response?.data?.image;
		})
		.catch(() => {})
		.then(() => {
			return api.patch(
				`/account/${contentID}/customization/avatar?type=account`,
				avatarData
			);
		});
};
export const changeBanner = (contentID, bannerData) => {
	// regular
	if (bannerData?.field === "text")
		return api.patch(
			`/account/${contentID}/customization/banner?type=account`,
			bannerData
		);
	const file = bannerData?.banner;

	const imageData = new FormData();
	imageData.append("image", file);
	// image
	return api
		.post(`/accesspoint/image/upload/${contentID}?type=account`, imageData)
		.then((response) => {
			bannerData.banner = response?.data?.image;
		})
		.catch(() => {})
		.then(() => {
			return api.patch(
				`/account/${contentID}/customization/banner?type=account`,
				bannerData
			);
		});
};
export const updateAboutMe = (contentID, aboutMeData) =>
	api.patch(
		`/account/${contentID}/customization/about?type=account`,
		aboutMeData
	);
export const checkYoutubeVideo = (input) =>
	api.get(`account/checkurl?input=${input}`);
export const changeMusic = (musicData, contentID) =>
	api.patch(
		`/account/${contentID}/customization/music?type=account`,
		musicData
	);

// general settings
export const changeGeneralSettings = (updatedSettings) =>
	api.patch(`/account/settings/general`, updatedSettings);
export const changeUsername = (formData) =>
	api.patch(`/account/settings/username`, formData);

// password editing
export const requestPasswordReset = (formData) =>
	api.post("/account/security/request_password_reset", formData);

// * SHARE integration
// Note: Some of these only needs the LSS account ID, which is in the headers already, so there is no need for an extra ID parameter.
export const getShareAuthenticationStatus = () =>
	api.get("/account/SHARE/get_share_auth_status");
export const requestShareAuthCode = (formData) =>
	api.post("/account/SHARE/request_share_auth_code", formData);
export const cancelShareAuth = () =>
	api.post("/account/SHARE/cancel_share_auth");
export const unlinkWithShareBot = () => api.post("/account/SHARE/unlink_share");

// profile - social
export const getFriendsListPage = (userID, friendData) =>
	api.get(`/profile/${userID}/friends?friendspage=${friendData.friendspage}`);
export const getIncomingFriendRequestsPage = (userID, friendData) =>
	api.get(
		`/profile/${userID}/friends/incoming?inreqpage=${friendData.inreqpage}`
	);
export const getOutgoingFriendRequestsPage = (userID, friendData) =>
	api.get(
		`/profile/${userID}/friends/outgoing?outreqpage=${friendData.outreqpage}`
	);
export const getBlockListPage = (userID, blockData) =>
	api.get(`/profile/${userID}/blocklist?blockpage=${blockData.blockpage}`);
export const toggleFriend = (userID, friendData) =>
	api.patch(`/profile/${userID}/friend`, friendData);
export const toggleFollow = (userID) => api.patch(`/profile/${userID}/follow`);
export const toggleUserBlock = (userID) =>
	api.patch(`/profile/${userID}/block`);

// profile - comments
export const getProfileComment = (id) => api.get(`/profile/comment/${id}`);
export const getProfileComments = (id) => api.get(`/profile/${id}/comments`);
export const getProfileCommentsPage = (id, commentData) =>
	api.get(`/profile/${id}/comments?page=${commentData.page}`);

export const createProfileComment = (id, profileCommentData) =>
	api.post(`/profile/${id}/comment`, profileCommentData);
export const editProfileComment = (id, contentID, commentData) =>
	api.patch(
		`/profile/${id}/comments/${contentID}/edit?type=profile_comment`,
		commentData
	);
export const reactProfileComment = (commentID, profileCommentData) =>
	api.patch(
		`/profile/${profileCommentData?.origin}/comments/${commentID}/react`,
		profileCommentData
	);
export const deleteProfileComment = (contentID, deleteData) =>
	api.patch(
		`/profile/comments/${contentID}/delete?type=profile_comment&profile=${deleteData.profileID}`
	);
export const obliterateProfileComment = (contentID, deleteData) =>
	api.delete(
		`/profile/comments/${contentID}/obliterate?type=profile_comment&profile=${deleteData.profileID}`
	);
// profile - threads
export const getThreadReply = (id, commentID) =>
	api.get(`/profile/${id}/comments/${commentID}`);
export const getThreadReplyPage = (id, commentID, replyData) =>
	api.get(
		`/profile/${id}/comments/${commentID}?page=${replyData.page}&threadpage=${replyData.threadpage}`
	);
export const createProfileCommentReply = (profileID, commentID, replyData) =>
	api.post(`/profile/${profileID}/comments/${commentID}/create`, replyData);
export const editProfileCommentReply = (id, contentID, commentData) =>
	api.patch(
		`/profile/${id}/comments/replies/${contentID}/edit?type=profile_reply`,
		commentData
	);
export const deleteProfileReply = (replyID, commentID, profileID) =>
	api.patch(
		`/profile/${profileID}/comments/${commentID}/replies/${replyID}/delete?type=profile_reply`
	);
export const obliterateProfileReply = (replyID, commentID, profileID) =>
	api.delete(
		`/profile/${profileID}/comments/${commentID}/replies/${replyID}/obliterate?type=profile_reply`
	);
// profile - other
export const getProfile = (id) => api.get(`/profile/get/${id}`);
export const moderateUser = (id, moderationData) =>
	api.patch(`/profile/${id}/moderate`, moderationData);

// levels
export const getLevelsList = (levelsData) => {
	const params = new URLSearchParams(levelsData).toString();
	return api.get(`/levels/filter/get?${params}`);
};
export const getSharedLevelsPage = (levelsData) =>
	api.get(
		`/levels/shared/get?page=${levelsData.page}&searchQuery=${levelsData.searchQuery}&game=${levelsData.game}&keep=${levelsData.keep}`
	);
export const getFeaturedLevelsPage = (levelsData) => {
	const params = new URLSearchParams(levelsData).toString();
	return api.get(`/levels/featured/get?${params}`);
};
export const getFavouriteLevels: any = (levelsData) => {
	const requestUrl =
		`/levels/favourites/${levelsData?.id}/get` +
		`?page=${levelsData?.page}&game=${levelsData?.game}&sort=${levelsData?.sort}` +
		`&keep=${levelsData?.keep}&count=${levelsData?.count}&authors=${levelsData?.authors}`;
	return api.get(requestUrl);
};
export const getLevel = (levelData) =>
	api.get(
		`/levels/${levelData.id}?random=${levelData.random}&game=${levelData.game}&userID=${levelData.userID}&sample=${levelData.sample}&keep=${levelData.keep}`
	);
export const updateLevelVideo = (levelData) =>
	api.patch(`/levels/${levelData.id}/video`, levelData);

// create a level and a thumbnail
export const createLevel: any = (formData, thumbnailIsFile, imageData) => {
	const levelValue = formData.get("level");
	const parsedLevel = JSON.parse(levelValue);
	if (thumbnailIsFile) {
		return api
			.post(`/accesspoint/image/upload/null`, imageData)
			.then((response) => {
				parsedLevel.thumbnail = response?.data?.image;
				parsedLevel.deletehash = response?.data?.deletehash;
				formData.set("level", JSON.stringify(parsedLevel));
			})
			.catch(() => {})
			.then(() => {
				return api.post("/levels", formData);
			});
	} else return api.post("/levels", formData);
};

// update a level and its thumbnail
export const updateLevel = (formData, thumbnailIsFile, imageData) => {
	const levelValue = formData.get("level");
	const parsedLevel = JSON.parse(levelValue);

	// upload the thumbnail if applicable
	if (thumbnailIsFile) {
		return api
			.post(`/accesspoint/image/upload/${parsedLevel._id}`, imageData)
			.then((response) => {
				parsedLevel.thumbnail = response?.data?.image;
				parsedLevel.deletehash = response?.data?.deletehash;
				formData.set("level", JSON.stringify(parsedLevel));
			})
			.catch(() => {})
			.then(() => {
				// update the level after image upload
				return api.patch(
					`/levels/${parsedLevel._id}/update/?type=level`,
					formData
				);
			});
	} else {
		// If thumbnail is not being uploaded, directly patch the level
		return api.patch(
			`/levels/${parsedLevel._id}/update/?type=level`,
			formData
		);
	}
};
export const removeLevelThumbnail = (levelID) =>
	api.patch(`/levels/${levelID}/remove_thumbnail/?type=level`);

//! access data forces out 2 different routers to change middleware
export const manageLevelAccess = (id, accessData) =>
	api.patch(`/levels/${id}/manage/${accessData.mode}?type=level`, accessData);

export const deleteLevel = (id) => api.patch(`/levels/${id}/delete?type=level`);
export const restoreLevel = (id) =>
	api.patch(`/levels/${id}/restore?type=level`);
export const unfeatureLevel = (id) =>
	api.patch(`/levels/${id}/unfeature?type=level`);
export const featureLevel = (id, featureData) =>
	api.patch(`/levels/${id}/feature?type=level`, featureData);
export const favouriteLevel = (id) => api.patch(`/levels/${id}/favourite`);
export const obliterateLevel = (id) => api.delete(`/levels/${id}`);
export const addToPlayCount = (levelID) => api.patch(`/levels/${levelID}/play`);
export const addToPlayCountAsGuest = (levelID) =>
	api.patch(`/levels/${levelID}/play_guest`);
export const rateLevel = (id, rateData) =>
	api.patch(`/levels/${id}/rate`, rateData);
export const removeLevelRate = (contentID, rateData) =>
	api.patch(
		`/levels/${contentID}/rate/${rateData.user}/remove?index=${rateData.index}&type=level_rate`
	);

// level comments
export const getLevelComment = (id) => api.get(`/levels/comment/${id}`);
export const getLevelCommentsPage = (id, levelCommentsData) =>
	api.get(
		`/levels/${id}/comments?page=${levelCommentsData.page}&sort=${levelCommentsData?.sort}&type=${levelCommentsData?.type}`
	);
export const getLevelCommentsSet = (id) =>
	api.get(`/levels/comments/users/${id}`);

export const createLevelComment = (id, levelCommentData) =>
	api.post(`/levels/${id}/comment`, levelCommentData);
export const editLevelComment = (id, contentID, commentData) =>
	api.patch(
		`/levels/${id}/comments/${contentID}/edit?type=level_comment`,
		commentData
	);
export const deleteLevelComment = (levelID, commentID) =>
	api.patch(
		`/levels/${levelID}/comments/${commentID}/delete?type=level_comment`
	);
export const obliterateLevelComment = (levelID, commentID) =>
	api.delete(
		`/levels/${levelID}/comments/${commentID}/obliterate?type=level_comment`
	);
export const reactLevelComment = (commentID, levelCommentData) =>
	api.patch(
		`/levels/${levelCommentData.origin}/comments/${commentID}/react`,
		levelCommentData
	);
// level comments - threads
export const getLevelCommentReply = (id, commentID) =>
	api.get(`/levels/${id}/comments/${commentID}`);
export const getLevelCommentReplyPage = (id, commentID, replyData) =>
	api.get(
		`/levels/${id}/comments/${commentID}?page=${replyData.page}&threadpage=${replyData.threadpage}`
	);
export const createLevelCommentReply = (levelID, commentID, replyData) =>
	api.post(`/levels/${levelID}/comments/${commentID}/create`, replyData);
export const editLevelCommentReply = (levelID, contentID, commentData) =>
	api.patch(
		`/levels/${levelID}/comments/replies/${contentID}/edit?type=level_reply`,
		commentData
	);
export const deleteLevelCommentReply = (replyID, commentID, levelID) =>
	api.patch(
		`/levels/${levelID}/comments/${commentID}/replies/${replyID}/delete?type=level_reply`
	);
export const obliterateLevelCommentReply = (replyID, commentID, levelID) =>
	api.delete(
		`/levels/${levelID}/comments/${commentID}/replies/${replyID}/obliterate?type=level_reply`
	);

// users
export const returnUserByToken = () => api.get(`/users/get_id_from_token`);
export const deleteUserSession = () => api.delete(`/users/logout`);
export const fetchAllUsers = () => api.get("/users");
export const getMembersPage = (membersData) =>
	api.get(
		`/users?page=${membersData.page}&searchQuery=${membersData.searchQuery}`
	);
export const getUserSet = (usersData) => api.post("/users/set", usersData);
export const getUser = (id) => api.get(`/users/${id}`);
export const getUserRates = (id, page) =>
	api.get(`/users/${id}/rates?page=${page}&type=account`);
export const getUsersWithAccess = (authorIds) =>
	api.get(`/users/levelaccess/${authorIds}`);
export const getAboutPage = () => api.get("/users/about/get");
export const login = (formData) => api.post("/users/login", formData);
export const register = (formData) => api.post("/users/register", formData);
export const updateUserLevels = (id) => api.patch(`/users/${id}`);
export const getOnlineUsers = (allUsers) =>
	api.get(`/users/online/get?all=${allUsers}`); // get currently online users

// infractions
export const getInfractions = (target, infractionData) =>
	api.get(
		`/users/infractions/${target}?page=${infractionData.infractionpage}`
	);
export const markReadInfraction = (target) =>
	api.patch(`/users/infractions/view/${target}`);

// verify user
export const verifyUser = (formData) => api.post("/verify", formData);
export const resendVerificationMail = (formData?) =>
	api.post("/verify/resend_mail", formData);
export const fetchEmail = (formData) =>
	api.post("/verify/check_email", formData);
export const changeEmail = (formData) =>
	api.patch("/verify/change_email", formData);

// --- announcements --- //
export const fetchAnnouncements = () => api.get(`/announcements/get`);
export const getAnnouncementById = (id) => api.get(`/announcements/${id}/get`);
export const createAnnouncement = (announcementData) =>
	api.post(`/announcements/post`, announcementData);
export const editAnnouncement = (contentID, announcementData) =>
	api.patch(
		`/announcements/${contentID}/edit?type=announcement`,
		announcementData
	);
export const deleteAnnouncement = (contentID) =>
	api.delete(`/announcements/${contentID}/delete?type=announcement`);
export const reactAnnouncement = (announcementID, mode) =>
	api.patch(`/announcements/${announcementID}/react/${mode}`);
export const voteOnPoll = (announcementID, announcementData) =>
	api.patch(`/announcements/${announcementID}/vote`, announcementData);
export const getAnnouncementsPage = (announcementsData) =>
	api.get(`/announcements/get?page=${announcementsData.page}`);

// --- announcements:  threads --- //
export const getAnnouncementReply = (announcementID) =>
	api.get(`/announcements/${announcementID}/replies`);
export const getAnnouncementReplyPage = (announcementID, replyData) =>
	api.get(
		`/announcements/${announcementID}/replies?threadpage=${replyData.threadpage}`
	);
export const createAnnouncementReply = (announcementID, replyData) =>
	api.post(`/announcements/${announcementID}/replies/create`, replyData);
export const editAnnouncementReply = (announcementID, contentID, replyData) =>
	api.patch(
		`/announcements/${announcementID}/replies/${contentID}/edit?type=announcement_reply`,
		replyData
	);
export const deleteAnnouncementReply = (replyID, announcementID) =>
	api.patch(
		`/announcements/${announcementID}/replies/${replyID}/delete?type=announcement_reply`
	);
export const obliterateAnnouncementReply = (replyID, announcementID) =>
	api.delete(
		`/announcements/${announcementID}/replies/${replyID}/obliterate`
	);
// --- notifications --- //
export const getNotifications = (notificationData) =>
	api.patch(`/notifications/get`, notificationData);
export const markNotificationAsRead = (notification) =>
	api.patch(`/notifications/hasread/${notification}`);
export const dismissNotification = (notification) =>
	api.delete(`/notifications/dismiss/${notification}`);
export const subscribeToThread = (id, force, type) =>
	api.patch(`/notifications/thread/${force}/${id}?type=${type}`);

// --- user dasboard --- //
export const getDashboard = (exclude, rewardState) =>
	api.get(`/dashboard/get?exclude=${exclude}&rewardState=${rewardState}`);
export const claimReward = (contentID) =>
	api.patch(`/dashboard/redeem/reward/${contentID}?type=reward`);
export const claimLevelRewards = (contentID) =>
	api.patch(`/dashboard/redeem/level/${contentID}?type=level`);
export const getXpFree = (input) =>
	api.patch(`/dashboard/test/${input.method}/${input.amount}`);

//! leader only
export const notifyMaintenance = () => api.post("/notifications/maintenance");

// --- events --- //
export const getEventSubmissions = () => api.get("/events/submissions/get");
export const createEventSubmission = (submissionContent) =>
	api.post("/events/submissions/create", submissionContent);

// --- report --- //
export const sendReport = (newReport) => api.post("/report", newReport);

// -- staff dashboard -- //
export const toggleRaidMode = () => api.patch("/staff/raid/switch");
export const getRaidModeStatus = () => api.get("/staff/raid/get");
export const forceEmailFetch = (target) =>
	api.get(`/staff/force/email/get/${target}`);
export const forceVerification = (target) =>
	api.patch("/staff/force/verify", target);
export const forceDeactivation = (target) =>
	api.patch("/staff/force/deactivate", target);

// --- homepage --- //
export const getHomepage = (homepageData) =>
	api.get(`/app/homepage/get?youtube=${homepageData.getYouTubeVideo}`);

// --- games --- //
export const getGames = () => api.get(`/app/games/get`);

// --- hints --- //
export const getHints = () => api.get(`/staff/hints/get`);
export const createHint = (hint) => api.post("/staff/hints/create", hint);
export const deleteHint = (hint) => api.put(`/staff/hints/delete/${hint}`);

// - passive checks - //
export const pingServer = (mode, hidden) =>
	api.post(`/app/intervals/${mode}?hidden=${hidden}`);
